import React, {Fragment} from 'react';
import {GatsbyImage} from  'gatsby-plugin-image';
import Overcast from './Overcast';
import { useWindowDimensions } from '../context/WindowDimensionsProvider';

const Hero = (props) => {
  const { windowHeight, windowWidth } = useWindowDimensions()
  return(
    <Fragment>
      <div className={props.className}>
        {
          windowWidth >= 768
          ? <GatsbyImage image={props.fullWidth} style={{height: windowHeight/1.5}} alt="Hero Image of Stein Erik Olsen"/>
          : <GatsbyImage image={props.fullWidth} style={{height: windowHeight/3}} alt="Hero Image of Stein Erik Olsen"/>
        }
        </div>
      </Fragment>
  )
}
export default Hero;
