import React from 'react';
import DiscHero from "./DiscHero";


const LatestRelease = (props) => {
  let release = props.release.node.frontmatter;
  return(
    <div className="disc flex center column">
      <h2 style={{paddingTop: '5rem', paddingBottom: '0rem', marginTop: '0', marginBottom: '0'}}>Latest release</h2>
      <div className="flex row center" style={{width: '100%'}}>
        <div className="discInfo">
          <DiscHero
          fixed={release.img.childImageSharp.gatsbyImageData}
          title={release.title}
          quote={release.quote}
          author={release.author}
          slug={props.release.node.fields.slug}
          />
          </div>
          
        </div>
      </div>
        
  )
}
export default LatestRelease;