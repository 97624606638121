import React from 'react';
import { useWindowDimensions } from '../context/WindowDimensionsProvider';
import { Link } from "gatsby";
import {GatsbyImage} from  "gatsby-plugin-image";
import Hero from "../../components/modules/Hero";

const QuoteView = (props) => {
  const { windowHeight, windowWidth, footerHeight } = useWindowDimensions();
  const sortedProps = props.postList.sort((a,b)=>(a.date > b.date) ? -1 : 1);
  return(
    <div className="quotes flex column">
      <Hero fullWidth={props.quoteView} className="hero"/>
      <div className="flex column wrap center" style={{minHeight: windowHeight-footerHeight}}>
        {/* Your post list here. */
        sortedProps.map((post, i) => (
          <div key={i} className="quote basePad flex center column" style={{flex: '1 1 auto'}}>
            <i>{post.quote}</i>
            <p style={{marginBottom: '0'}}>{post.author}</p>
            { post.reviewlink? <a href={post.reviewlink} target="_blank"><i>{post.title}</i></a> : <i>{post.title}</i> }
            <hr/>
          </div>
        ))}
      </div>
    </div>
  )
}
export default QuoteView;
