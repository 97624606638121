import { render } from 'react-dom'
import React from 'react'
import { navigate } from "gatsby"
import { GatsbyImage } from  'gatsby-plugin-image'
import Hero from '../modules/Hero';
import Header from '../modules/Header';

const MasonrySass = (propp) => {
  const imageList = propp.postList;
  return (
    <div className="masonryView flex center column">
      <Hero fullWidth={propp.masonryView} className="hero"/>
      {/*<Header title="Gallery" />*/}
      <div className="flex row wrap basePad center container">
        <div className="list">
          { imageList.map((item,i) => (
            <div
              className="item"
              key={i}
              onClick={(()=> navigate(item.path))} >
              <GatsbyImage image={item.img.childImageSharp.gatsbyImageData} to={item.path} alt={`Photo by: ${item.photo}`}/>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
export default MasonrySass;