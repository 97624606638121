import React from "react";
import DiscographyView from './DiscographyView';
import QuoteView from './QuoteView';
import GalleryView from './GalleryView';
import MasonrySass from '../masonry/MasonrySass';
import { Link } from "gatsby";
import {GatsbyImage} from  "gatsby-plugin-image";

class PostListing extends React.Component {
  state = {
    view: 'default'
  }
  getPostList() {
    const postList = [];
    this.props.postEdges.forEach(postEdge => {
      postList.push({
        path: postEdge.node.fields.slug,
        tags: postEdge.node.frontmatter.tags,
        img: postEdge.node.frontmatter.img,
        title: postEdge.node.frontmatter.title,
        date: postEdge.node.fields.date,
        excerpt: postEdge.node.excerpt,
        timeToRead: postEdge.node.timeToRead,
        category: postEdge.node.frontmatter.category,
        quote: postEdge.node.frontmatter.quote,
        author: postEdge.node.frontmatter.author,
        reviewlink: postEdge.node.frontmatter.reviewlink,
        photo: postEdge.node.frontmatter.photo,
        alt: postEdge.node.frontmatter.alt
      });
    });
    return postList;
  }
  componentDidMount = () => {
    let postList = this.getPostList();
    this.setState({
      view: postList[0].category
    })
  }
  render = () => {
    const postList = this.getPostList();
    if(this.state.view == "discography") {
      return (
        <DiscographyView postList={postList} discView={this.props.discView} />
      )
    } else if(this.state.view == "reviews") {
      return (
        <QuoteView postList={postList} quoteView={this.props.quoteView}/>
      )
    } else if(this.state.view == "gallery") {
      return (
        //<GalleryView postList={postList} />
        <MasonrySass postList={postList} masonryView={this.props.masonryView}/>
      )
    } else {
      return (
        <div>
          {/* Your post list here. */
          postList.map((post,i) => (
            <Link to={post.path} key={i}>
              <div className="flex center">
                <div>
                  <h2>{post.title}</h2>
                </div>
              </div>
            </Link>
          ))}
        </div>
      )
    }
  }
}

export default PostListing;
