import React, { useState, useEffect } from 'react'
import { useTransition, animated, config } from 'react-spring'

const SingleQuote = (props) => {
  const [index, set] = useState(0)
  const transitions = useTransition(props.quotes[index], {
    from: { opacity: 0  },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: config.slow,
  })
  useEffect(() => {
    const fuii = setInterval(() => {
        set(state => (state + 1) % props.quotes.length)
      }, 7500)
      return () => clearInterval(fuii)
    },[props.quotes.length])

  return transitions(({ opacity }, item )=> (
    <animated.div
      style={{
      position: 'absolute',
      opacity: opacity.to({ range: [0.0, 1.0], output: [0, 1] }),}}
      key={item.quote} 
      className="transitionedQuote"
      >
      <i>{item.node.frontmatter.quote}</i>
      <p>{item.node.frontmatter.author}</p>
      { item.node.frontmatter.reviewlink? <a href={item.node.frontmatter.reviewlink} target="_blank"><i>{item.node.frontmatter.title}</i></a> : <i>{item.node.frontmatter.title}</i> }

      <hr/>
    </animated.div>
  ))
}

export default class Quote extends React.Component {
  render = () => {
    let props = this.props;
    let quotes = props.quotes;
    return(
      <div className="flex center column">
        <SingleQuote quotes={props.quotes}/>
      </div>
    )
  }
}

  // OLD STATE INTERVALLER
  {/* state = {
    currentQuoteIndex: 0,
    intervalId: 0
  }
  quoteFlow = (data) => {
    let allQuotes = Object.values(data);
    let currentQuoteIndex = this.state.currentQuoteIndex;
    let currentQuote = allQuotes[0];
    currentQuoteIndex = (currentQuoteIndex+1) % allQuotes.length;
    currentQuote = allQuotes[currentQuoteIndex];
    console.log(currentQuote.node.frontmatter.quote)
    this.setState({
      currentQuoteIndex: currentQuoteIndex
    })
  }
  componentDidMount = () => {
    let intervalId = setInterval(()=>{this.quoteFlow(this.props.quotes)}, 10000);
    this.setState({
      intervalId: intervalId
    })
  }
  componentWillUnmount = () => {
    clearInterval(this.state.intervalId)
  } */}

  // OLD SINGLEQUOTECOMPONENT
  {/*const SingleQuote = (sQuote) => {
  const props = useSpring({opacity: 1, from: {opacity: 0}})
  let quote = sQuote.sQuote.quote,
      author = sQuote.sQuote.author,
      title = sQuote.sQuote.title;

  return(
    <animated.div style={props}>
      <i>{quote}</i>
      <p>- {author}</p>
      <i>{title}</i>
    </animated.div>
  )
} */}
