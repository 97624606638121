import React from "react";
import Helmet from "react-helmet";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import PostListing from "../components/PostListing/PostListing";
import Hero from '../components/modules/Hero';
import HomeLinks from '../components/modules/HomeLinks';
import LatestRelease from '../components/modules/LatestRelease';
import Quotes from '../components/modules/Quotes';
import SpotifyPlaylist from '../components/modules/SpotifyPlaylist';
import Seo from "../components/modules/Seo";
import config from "../../data/SiteConfig";

class Index extends React.Component {
  render() {
    return (
      <Layout>
        <div className="index-container home">
          <Helmet title={config.siteTitle} />
          <Hero fullWidth={this.props.data.imageOne.childImageSharp.gatsbyImageData} className="homeHero" height="80%"/>
          <HomeLinks />
          <Quotes />
          <LatestRelease release={this.props.data.latest.edges[0]} />
        </div>
      </Layout>
    );
  }
}

export default Index;

/* eslint no-undef: "off" */
export const pageQuery = graphql`
  query {
    imageOne: file(relativePath: { eq: "img/SteinErikOlsenFrontHero.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout:FULL_WIDTH)
      }
    }
    latest: allMarkdownRemark(
      limit: 1
      filter: { frontmatter: { category: { eq: "discography" } } }
      sort: {
        fields: frontmatter___date
        order: DESC
      }
    ) {
      edges {
        node {
          fields {
            slug
            date
          }
          frontmatter {
            title
            date
            category
            artists
            quote
            author
            img {
              childImageSharp {
                gatsbyImageData(layout:CONSTRAINED, width: 340)
              }
            }
          }
        }
      }
    }  
  }
`

{/*
  about: allMarkdownRemark(
    limit: 1
    filter: { frontmatter: { category: { eq: "bio" } } }
  ) {
    totalCount
    edges {
      node {
        fields {
          slug
          date
        }
        excerpt(pruneLength: 500)
        timeToRead
        frontmatter {
          title
          date
          category
        }
      }
    }
  }
  */}
