import React from 'react';
import {Link} from 'gatsby';
import { useWindowDimensions } from '../context/WindowDimensionsProvider';


const HomeLinks = (props) => {
  const { windowWidth } = useWindowDimensions()
  if(windowWidth>=768){
    return(
      <div className="hero homeAbout flex center column">
        <div className="basePad flex center wrap" style={{paddingTop: '0rem', width: '100%'}}>
          {/*<p>{props.post.node.excerpt}</p>*/}
          <div className="flex center">
            <Link to="/about">
              <h3>About</h3>
            </Link>
          </div>
          <div className="flex center">
            <Link to="/discography">
              <h3>CDs</h3>
            </Link>
          </div>
          <div className="flex center">
            <Link to="/reviews">
              <h3>Reviews</h3>
            </Link>
          </div>
          <div className="flex center">
            <Link to="/gallery">
              <h3>Gallery</h3>
            </Link>
          </div>
          <div className="flex center">
            <Link to="/the-grieg-academy">
              <h3>Grieg Academy</h3>
            </Link>
          </div>
          <div className="flex center">
            <Link to="/contact">
              <h3>Contact</h3>
            </Link>
          </div>
        </div>
      </div>
    )
  } else {
    return (
      <div></div>
    )
  }

}
export default HomeLinks;
