import React from "react";
import Quote from './Quote';
import { StaticQuery, graphql } from "gatsby";

export default class Quotes extends React.Component {
  render = () => {
    let props = this.props;
    return (
      <StaticQuery
        query={graphql`
          query {
            allMarkdownRemark(
              filter: { frontmatter: { category: { eq: "frontpagereviews" }}}
              sort: {
                fields: [frontmatter___date]
                order: DESC
              }
            ) {
              edges {
                node {
                  frontmatter {
                    quote
                    author
                    title
                    reviewlink
                  }
                }
              }
            }
          }
        `}
       render={data => (
        <div className="quotes homeQuotes flex center column basePad">
          <Quote quotes={data.allMarkdownRemark.edges} />
        </div>
       )}
     />
    )
  }
}
