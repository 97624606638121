import React from 'react';
import {GatsbyImage} from  'gatsby-plugin-image';
import Overcast from './Overcast';
import {Link} from 'gatsby';

const DiscHero = (props) => {
  return(
    <div className="flex basePad discHero">
      <GatsbyImage image={props.fixed} alt={`Cover image of ${props.title}`} />
      <div className="flex column discMeta">
        <h1>{props.title}</h1>
        {
          props.artists?.map(x => {
            return <h3 key={x}>{x}</h3>
          })
        }
        { props.quote? <i>{props.quote}</i> : ''}
        { props.author? <p>{props.author}</p> : ''}
        { props.slug? <Link to={props.slug}><button><h3>Read more</h3></button></Link> : ''}


      </div>
    </div>
  )
}
export default DiscHero;
