import React from 'react';
import { useWindowDimensions } from '../context/WindowDimensionsProvider';
import { Link } from "gatsby";
import Hero from "../../components/modules/Hero";
import {GatsbyImage} from  "gatsby-plugin-image";
import Header from '../../components/modules/Header';

const DiscographyView = (props) => {
  const { windowHeight, windowWidth, footerHeight } = useWindowDimensions();
  return(
      <div className="noTriangleFooter flex column" style={{width: '100%'}}>
        <Hero fullWidth={props.discView} className="hero"/>
        <div className="noTriangleHero flex basePad center column" style={{paddingBottom: "0"}}>
          <h1 style={{color: '#ccc'}}>Discography</h1>
          <i style={{color: '#ccc'}}>"Stein-Erik Olsen has a large and valuable discography on the Simax lable."</i>
          <p style={{color: '#ccc'}}>Al Kunze, Soundboard USA July 2019</p>
        </div>
        <div className="flex center row wrap basePad" style={{minHeight: windowHeight-footerHeight}}>
        {/* Your post list here. */
        props.postList.map(post => (
          <div className="card" key={post.title}>
            <Link to={post.path}>
              <div className="flex center">
                <GatsbyImage image={post.img.childImageSharp.gatsbyImageData} alt={`Image cover of ${post.title}`} />
                {/*<div className="cardMeta flex column">
                  <h2>{post.title}</h2>
                  <h4>{post.date}</h4>
                </div>*/}
              </div>
            </Link>
          </div>
        ))}
      </div>
    </div>
  )
}
export default DiscographyView;
